<template>
  <v-app id="homepage">
    <core-toolbar />

    <v-main class="pb-0">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>

    <core-footer v-if="$route.meta.name !== 'Maps'" />
  </v-app>
</template>

<script>
export default {
  name: 'Home',
  metaInfo() {
    return {
      title: 'هكتار بلس'
    }
  }
}
</script>
